import clsx from "clsx";
import type { ReactNode } from "react";

interface PageContainerProps {
  hideBorder?: boolean;
  children: ReactNode;
}

export function PageContainer({ hideBorder, children }: PageContainerProps) {
  return (
    <div className={clsx("relative", "px-4", "max-w-8xl", "mx-auto", "md:px-8")}>
      <div className={clsx("relative", !hideBorder && "2xl:border-x", !hideBorder && "2xl:border-x-blue-200")}>
        {children}
      </div>
    </div>
  );
}
